<template>
  <div>
    <logo/>
    <br>
    If you’re in emotional distress or suicidal crisis, know that someone is here to talk to you.<br>
    <br>
    <table>
      <thead>
          <tr>
              <th>
                  <strong>Country</strong>
              </th>
              <th>
                  <strong>Hotline organization</strong>
              </th>
              <th>
                  <strong>Website</strong>
              </th>
              <th>
                  <strong>Phone number</strong>
              </th>
          </tr>
      </thead>
      <tbody></tbody>
      <tbody>
          <tr>
              <td>Argentina</td>
              <td>Centro de Asistencia al Suicida</td>
              <td>
                  <a class="link" href="http://www.asistenciaalsuicida.org.ar" rel="noopener" target="_blank">www.asistenciaalsuicida.org</a>
              </td>
              <td>(011) 5275-1135</td>
          </tr>
          <tr>
              <td>Australia</td>
              <td>Lifeline Australia</td>
              <td>
                  <a class="link" href="https://www.lifeline.org.au/" rel="noopener" target="_blank">www.lifeline.org</a>
              </td>
              <td>13 11 14</td>
          </tr>
          <tr>
              <td>Austria</td>
              <td>TelefonSeelsorge Österreich</td>
              <td>
                  <a class="link" href="https://www.telefonseelsorge.at/" rel="noopener" target="_blank">www.telefonseelsorge.at</a>
              </td>
              <td>142</td>
          </tr>
          <tr>
              <td>Belgium</td>
              <td>Centre de Prévention du Suicide</td>
              <td>
                  <a class="link" href="https://www.preventionsuicide.be/" rel="noopener" target="_blank">www.preventionsuicide.be</a>
              </td>
              <td>0800 32 123</td>
          </tr>
          <tr>
              <td>Belgium</td>
              <td>Zelfmoord 1813</td>
              <td>
                  <a class="link" href="https://www.zelfmoord1813.be/" rel="noopener" target="_blank">www.zelfmoord1813.be</a>
              </td>
              <td>1813</td>
          </tr>
          <tr>
              <td>Brazil</td>
              <td>Centro de Valorização da Vida</td>
              <td>
                  <a class="link" href="https://www.cvv.org.br/" rel="noopener" target="_blank">www.cvv.org</a>
              </td>
              <td>188</td>
          </tr>
          <tr>
              <td>Canada</td>
              <td>Crisis Services Canada</td>
              <td>
                  <a class="link" href="https://www.crisisservicescanada.ca/fr/" rel="noopener" target="_blank">www.crisisservicescanada.ca/fr</a>
              </td>
              <td>833-456-4566</td>
          </tr>
          <tr>
              <td>Chile</td>
              <td>Ministry of Health of Chile</td>
              <td>
                  <a class="link" href="https://www.hospitaldigital.gob.cl/" rel="noopener" target="_blank">www.hospitaldigital.gob</a>
              </td>
              <td>6003607777</td>
          </tr>
          <tr>
              <td>China</td>
              <td>北京心理危机研究与干预中心</td>
              <td>
                  <a class="link" href="http://www.crisis.org.cn/" rel="noopener" target="_blank">www.crisis.org</a>
              </td>
              <td>800-810-1117</td>
          </tr>
          <tr>
              <td>Costa Rica</td>
              <td>Colegio de Prof. en Psicología</td>
              <td>
                  <a class="link" href="https://psicologiacr.com/aqui-estoy/" rel="noopener" target="_blank">psicologiacr.com/aqui-estoy</a>
              </td>
              <td>2272-3774</td>
          </tr>
          <tr>
              <td>France</td>
              <td>SOS Amitié</td>
              <td>
                  <a class="link" href="https://www.sos-amitie.org/" rel="noopener" target="_blank">www.sos-amitie.org</a>
              </td>
              <td>09 72 39 40 50</td>
          </tr>
          <tr>
              <td>Germany</td>
              <td>TelefonSeelsorge Deutschland</td>
              <td>
                  <a class="link" href="http://www.telefonseelsorge.de" rel="noopener" target="_blank">www.telefonseelsorge.de</a>
              </td>
              <td>0800 1110111</td>
          </tr>
          <tr>
              <td>Hong Kong</td>
              <td>Suicide Prevention Services</td>
              <td>
                  <a class="link" href="http://www.sps.org.hk" rel="noopener" target="_blank">www.sps.org</a>
              </td>
              <td>2382 0000</td>
          </tr>
          <tr>
              <td>India</td>
              <td>iCall Helpline</td>
              <td>
                  <a class="link" href="https://icallhelpline.org/" rel="noopener" target="_blank">icallhelpline.org</a>
              </td>
              <td>9152987821</td>
          </tr>
          <tr>
              <td>Ireland</td>
              <td>Samaritans Ireland</td>
              <td>
                  <a class="link" href="https://www.samaritans.org/how-we-can-help/contact-samaritan" rel="noopener" target="_blank">www.samaritans.org/how-we-can-help</a>
              </td>
              <td>116 123</td>
          </tr>
          <tr>
              <td>Israel</td>
              <td>[Eran] ער"ן</td>
              <td>
                  <a class="link" href="https://www.eran.org.il/" rel="noopener" target="_blank">www.eran.org</a>
              </td>
              <td>1201</td>
          </tr>
          <tr>
              <td>Italy</td>
              <td>Samaritans Onlus</td>
              <td>
                  <a class="link" href="http://www.samaritansonlus.org/" rel="noopener" target="_blank">www.samaritansonlus.org</a>
              </td>
              <td>800 86 00 22</td>
          </tr>
          <tr>
              <td>Japan</td>
              <td>子供こどものSOS</td>
              <td>
                  <a class="link" href="https://www.mext.go.jp/a_menu/shotou/seitoshidou/06112210.htm" rel="noopener" target="_blank">www.mext.go.jp</a>
              </td>
              <td>81-0120-0-78310</td>
          </tr>
          <tr>
              <td>Japan</td>
              <td>まもろうよ こころ</td>
              <td>
                  <a class="link" href="https://www.mhlw.go.jp/mamorouyokokoro/" rel="noopener" target="_blank">www.mhlw.go</a>
              </td>
              <td>0570-064-556</td>
          </tr>
          <tr>
              <td>Malaysia</td>
              <td>Befrienders KL</td>
              <td>
                  <a class="link" href="https://www.befrienders.org.my/centre-in-malaysia" rel="noopener" target="_blank">www.befrienders.org</a>
              </td>
              <td>03-76272929</td>
          </tr>
          <tr>
              <td>Netherlands</td>
              <td>113Online</td>
              <td>
                  <a class="link" href="https://www.113.nl/" rel="noopener" target="_blank">www.113.nl</a>
              </td>
              <td>0800-0113</td>
          </tr>
          <tr>
              <td>New Zealand</td>
              <td>Lifeline Aotearoa Incorporated</td>
              <td>
                  <a class="link" href="https://www.lifeline.org.nz/" rel="noopener" target="_blank">www.lifeline.org</a>
              </td>
              <td>0800 543 354</td>
          </tr>
          <tr>
              <td>Norway</td>
              <td>Mental Helse</td>
              <td>
                  <a class="link" href="https://mentalhelse.no" rel="noopener" target="_blank">mentalhelse.no</a>
              </td>
              <td>116 123</td>
          </tr>
          <tr>
              <td>Philippines</td>
              <td>Department of Health</td>
              <td>
                  <a class="link" href="https://doh.gov.ph/NCMH-Crisis-Hotline" rel="noopener" target="_blank">doh.gov.ph/NCMH-Crisis-Hotline</a>
              </td>
              <td>0966-351-4518</td>
          </tr>
          <tr>
              <td>Portugal</td>
              <td>SOS Voz Amiga</td>
              <td>
                  <a class="link" href="http://www.sosvozamiga.org" rel="noopener" target="_blank">www.sosvozamiga.org</a>
              </td>
              <td>
                  213 544 545<br>963 524 660<br>912 802 669
              </td>
          </tr>
          <tr>
              <td>Russia</td>
              <td>Фонд поддержки детей</td>
              <td>
                  <a class="link" href="https://www.ya-roditel.ru/parents/helpline/" rel="noopener" target="_blank">www.ya-roditel.ru</a>
              </td>
              <td>8-800-2000-122</td>
          </tr>
          <tr>
              <td>Singapore</td>
              <td>Samaritans of Singapore</td>
              <td>
                  <a class="link" href="https://www.sos.org.sg/" rel="noopener" target="_blank">www.sos.org</a>
              </td>
              <td>1-767</td>
          </tr>
          <tr>
              <td>South Africa</td>
              <td>SA Depression and Anxiety Group</td>
              <td>
                  <a class="link" href="https://www.sadag.org/" rel="noopener" target="_blank">www.sadag.org</a>
              </td>
              <td>0800 567 567</td>
          </tr>
          <tr>
              <td>South Korea</td>
              <td>중앙자살예방센터</td>
              <td>
                  <a class="link" href="http://www.spckorea.or.kr" rel="noopener" target="_blank">www.spckorea.or</a>
              </td>
              <td>1393</td>
          </tr>
          <tr>
              <td>Spain</td>
              <td>Teléfono de la Esperanza</td>
              <td>
                  <a class="link" href="https://www.telefonodelaesperanza.org/" rel="noopener" target="_blank">www.telefonodelaesperanza.org</a>
              </td>
              <td>717 003 717</td>
          </tr>
          <tr>
              <td>Switzerland</td>
              <td>Die Dargebotene Hand</td>
              <td>
                  <a class="link" href="https://www.143.ch/" rel="noopener" target="_blank">www.143.ch</a>
              </td>
              <td>143</td>
          </tr>
          <tr>
              <td>Taiwan</td>
              <td>国际生命线台湾总会</td>
              <td>
                  <a class="link" href="http://www.life1995.org.tw/" rel="noopener" target="_blank">www.life1995.org</a>
              </td>
              <td>1995</td>
          </tr>
          <tr>
              <td>United Kingdom</td>
              <td>Samaritans</td>
              <td>
                  <a class="link" href="https://www.samaritans.org/how-we-can-help/contact-samaritan" rel="noopener" target="_blank">www.samaritans.org/how-we-can-help</a>
              </td>
              <td>116 123</td>
          </tr>
          <tr>
              <td>United States</td>
              <td>National Suicide Prevention Lifeline</td>
              <td>
                  <a class="link" href="https://suicidepreventionlifeline.org/" rel="noopener" target="_blank">suicidepreventionlifeline.org</a>
              </td>
              <td>988</td>
          </tr>
      </tbody>
    </table>
    <br>
    source: Google, 2022<br>
    <br>
    <div class="link" @click="sot.title=''">home</div>
    <br><br>
  </div>
</template>

<script>
import logo from './logo.vue'
export default {
  name: 'suicide',
  components: {
    logo
  },
  data: function() {
    return {
      sot: this.$root.$data
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/settings.scss";

table {
  text-align: left;
}
td {
  padding: 8px 8px 0 0;
  white-space: nowrap;
}

</style>
