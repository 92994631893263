<template>
  <div>
    <logo/>
    <h1>Free Online Notepad for Secure and Encrypted Note Sharing</h1>

    <p>Publicnote is a free online notepad where you can easily create and access notes by entering a title above — no account required.</p>

    <h2>Public and Private Notes</h2>

    <p>The visibility of your notes depends on the complexity of the title you choose. Simple titles lead to public notes that anyone can view and edit, while complex titles allow for private, securely encrypted notes. Remember, if you forget the title, there's no way to recover your note, so choose wisely!</p>

    <h2>Fun and Creative Use Cases</h2>
    <p>Publicnote is versatile and can be used for various entertaining and practical purposes:</p>

    <ul>
      <li>Sharing Ideas: Post your thoughts for others to read.</li>
      <li>Private Notes: For secure personal notes, choose longer, complex titles.</li>
      <li>Temporary Note Sharing: Use it as a Pastebin alternative for quick, public text sharing.</li>
      <li>Puzzles and Riddles: Challenge users with fun brain teasers.</li>
      <li>Jokes: Share a laugh by posting jokes and humor.</li>
      <li>ASCII Art: Create and share text-based art.</li>
      <li>Chatting with Strangers: Start a public conversation by sharing a note.</li>
      <li>Advertising: Promote events or services with public announcements.</li>
      <li>Dead Drops for Spies: Securely share information anonymously.</li>
      <li>Storytelling: Collaboratively create stories by inviting others to add their own lines or paragraphs.</li>
    </ul>

    <h2>How It Works</h2>

    <p>Every note you create is protected with <a class="link" href="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard" target="_blank" rel="noopener">AES-256</a> encryption, using the title as the encryption key. A <a class="link" href="https://en.wikipedia.org/wiki/SHA-2" target="_blank" rel="noopener">SHA-256</a> hash of the title indexes the encrypted note in our database, ensuring that the plaintext content is never exposed to anyone, including the website owner and hosting provider.</p>
    <br><br>
    <p>Inspired by the Bitcoin <a class="link" href="https://brainwallet.io" target="_blank" rel="noopener">brainwallet</a>, which allows users to create and access a Bitcoin just by entering a passphrase, Publicnote is designed on the same principles to ensure that your notes remain private and secure.</p>
    
    <h2>Open Source and Ad-Free Experience</h2>

    <p>Publicnote is proud to be an <a class="link" href="https://github.com/routman/publicnote.com" target="_blank" rel="noopener">open-source</a> platform, ensuring transparency and community engagement. Our service is completely ad-free, providing you with a distraction-free environment to create and share notes. Your donations help support our operating costs and are greatly appreciated.</p>

    <h2>Important Note</h2>
    
    <p>Please do not use Publicnote to store sensitive information. By using our service, you agree to our <div class="link" @click="sot.title = 'terms'">terms and conditions</div>.</p>

    <h2>Support Us</h2>

    <p>If you find Publicnote useful and would like to contribute, consider making a donation:</p>

    <ul>
    <li>Bitcoin: bc1q7fqwmtq2vaka8wwpjpnmlehe36qrgfmlw33vh9</li>
    <li>Litecoin: LYMSJ313xJaUsAmucuYRkVJmGB8Ut9VDz8</li>
    <li>Dogecoin: DATumCTp1QBG1Gpa3ko6bXPXccnFMFDgYC</li>
    <li>Ethereum: 0x6abD6f3df07c06e4137269D7187661dE37441218</li>
    </ul>
    <p>Explore the functionalities of Publicnote today and start creating your notes!</p>
    <br><br>
    <p>publicnote &copy; {{ new Date().getFullYear() }}</p>
  </div>
</template>

<script>
import logo from './logo.vue'
export default {
  name: 'home',
  components: {
    logo
  },
  data: function() {
    return {
      sot: this.$root.$data
    }
  }
}
</script>

<style scoped lang="scss">
@import "../assets/settings.scss";

h1, h2 {
  font-weight: normal;
  font-size: 18px;
}

p {
  display: inline;
}

li {
  margin-bottom: 8px;
}

</style>
